import React, { useState, useEffect, PropTypes, useRef } from "react"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Modules from '../components/modules'
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import OfficeDetailsBanner from "../components/Home/Banner/OfficeDetailsBanner";
import OfficeContent from "../components/office/OfficeContent";
import StickyFooter from "../components/Footer/StickyFooter";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "./scss/office-details-template.scss";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { GetReviewsOffice } from "../utils";



const OfficeDetails = (props) => {


  const [reviewData, setReviewData] = useState(0);
  const [renderComponent, setRenderComponent] = useState(true);
  const [state, setState] = React.useState({
    showMenu: false
  })

  const contactFormRef = useRef(null); // Create the ref
  const location = useLocation();

  useEffect(() => {
    fetchReviews();
  }, []);
  const fetchReviews = async () => {
    const reviewData = await GetReviewsOffice();
    setReviewData(reviewData);
  }
  const scrollToContactUs = () => {
    const contactUsSection = document.getElementById('contact-us-scroll');
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log('Contact Us section not found');
    }
  };
  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }
  const GQLPage = props.data.glstrapi?.office;
  const peoplesData = props.data.glstrapi?.peoples;
  const Page_Modules = props.data.glstrapi?.article?.Modules;
  const Site = props.data.glstrapi?.siteConfig;
  const openhours = props.data.glstrapi?.globalModule;
  const Schema_Data = {
    Dubai: {
      image: "https://ggfx-dachare.s3.eu-west-2.amazonaws.com/x.prod/770x570/DSC_2023_175a8c8898.webp",
      streetAddress: "Office 2101 & 2404, Marina Plaza",
      addressLocality: "Dubai Marina",
      addressRegion: "Dubai",
      addressCountry: "AE",
      postalCode: "171418",
      latitude: 25.075587094230272,
      longitude: 55.13981447115252,
    },
    Palm_Jumeirah: {
      image: "https://ggfx-dachare.s3.eu-west-2.amazonaws.com/x.prod/770x570/Real_Estate_Agents_in_Palm_Jumeirah_a922872b06.webp",
      streetAddress: "Retail 04 - Golden Mile 7",
      addressLocality: "The Palm Jumeirah",
      addressRegion: "Dubai",
      addressCountry: "AE",
      latitude: 25.110740454664143,
      longitude: 55.14225010000616,
    },
  };
  const OfficeLocation = location.pathname.split("/").includes("real-estate-agents-in-dubai-marina") ? "Dubai" : "Palm_Jumeirah"
  const location_link = process.env.GATSBY_SITE_URL + location.pathname;
  const schemaData = Schema_Data[OfficeLocation];
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "RealEstateAgent",
    "name": GQLPage?.OfficeName || "Dacha Real Estate",
    "image": schemaData.image,
    "@id": location_link,
    "url": location_link,
    "telephone": "+971 4 423 2006",
    "description": GQLPage?.Meta_Description || null,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": schemaData.streetAddress,
      "addressLocality": schemaData.addressLocality,
      "addressRegion": schemaData.addressRegion,
      "addressCountry": schemaData.addressCountry,
      ...(schemaData.postalCode && { "postalCode": schemaData.postalCode })
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": schemaData.latitude,
      "longitude": schemaData.longitude,
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      "opens": "09:30",
      "closes": "18:30",
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": reviewData[OfficeLocation]?.averageRating || null,
      "reviewCount": reviewData[OfficeLocation]?.reviewCount || null ,
    },
    "sameAs": [
      "https://www.facebook.com/dacharealestate/",
      "https://twitter.com/Dachateam",
      "https://www.instagram.com/dacharealestate/",
      "https://www.youtube.com/c/DachaRealEstateDubai/",
      "https://www.linkedin.com/company/dacharealestate/",
      "https://www.dacha.ae/",
    ],
  };

  let peoples = peoplesData?.filter((people) => { return people.Branch?.id == GQLPage.id }).slice(0, 8);

  let custom_css = ''
  let css_pagename = "";
  if (GQLPage.Custom_CSS_Classname) {
    custom_css = GQLPage.Custom_CSS_Classname
  }
  const wrapClass = GQLPage?.Pagename ? `office-details-template page-${GQLPage?.Pagename.toLowerCase().replace(/\s+/g, '-')}-wrap` : '';
  const layoutClass = GQLPage?.Layout ? `page-layout-${GQLPage?.Layout.toLowerCase().replace(/\s+/g, '-')}` : '';

  var metaTitle = GQLPage.Meta_Title;
  var metaDesc = GQLPage.Meta_Description;


  //Dynamic Schema For two offices
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(schemaMarkup);
    script.id = "schemaMarkupScript";

    // Remove existing script if present to avoid duplicates
    const existingScript = document.getElementById("schemaMarkupScript");
    if (existingScript) {
      existingScript.remove();
    }

    document.head.appendChild(script);

    return () => {
      // Cleanup script on unmount
      script.remove();
    };
  }, [schemaMarkup]);
  return (
    <div className={`office-details-template-wrap `}>
      <SEO title={metaTitle} description={metaDesc} location={props.location} />
      <SEO title={metaTitle} description={metaDesc} location={props.location} />
      <Header homeArticleId={props.pageContext} location={props.location} showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} TransparentHeader={GQLPage?.TransparentHeader} />
      <Breadcrumb data={''} tag={`detail-page`} name={GQLPage.OfficeName} category={`Contact`} />
      <OfficeDetailsBanner id={GQLPage.id} onCta2Click={scrollToContactUs} page={GQLPage} url={GQLPage.URL} content={GQLPage.Address} image={GQLPage.BannerImage} info={GQLPage.Header_Call_Info} cta_1={GQLPage.BannerCTAs.CTA1_Label} cta_2={GQLPage.BannerCTAs.CTA2_Label} cta_1_link={GQLPage.BannerCTAs.CTA1_Menu_Link} cta_2_link={GQLPage.BannerCTAs.CTA2_Menu_Link} video={GQLPage.Video_URL} rating={GQLPage.ShowGoogleRating} />
      <OfficeContent open_hours={openhours} contactFormRef={contactFormRef} peoplesData={peoples} modules={GQLPage.Modules} Add_Static_Content={GQLPage.Modules.Add_Static_Content} pagename = {GQLPage.OfficeName} imagetransforms={GQLPage.imagetransforms} id = {GQLPage.id} page={GQLPage} />
      {/* <NewsLetter /> */}
      <Footer />
      {GQLPage.Show_CTA_Sticky_Mobile &&
        <StickyFooter cta_1={GQLPage.BannerCTAs.CTA1_Label}
          cta_2={GQLPage.BannerCTAs.CTA2_Label}
          cta_1_link={GQLPage.BannerCTAs.CTA1_Menu_Link}
          cta_2_link={GQLPage.Header_CTA_2_Link}
          search={"Office_Request_Call_Back"}
          onCta2Click={scrollToContactUs}
        />

      }
    </div>

  )
}
export default OfficeDetails;


export const pageQuery = graphql`
  query OfficeQuery($id: ID!) {
    glstrapi {
    globalModule {
      Opening_Hours
    }
    peoples(   sort: "Sort:asc"
      where: {Publish: true }
    ) {
      Name
      Designation
      URL
      Tile_Image {
        alternativeText
        url
      }
      imagetransforms
      Video_URL
      Publish
      Branch {
        _id
        id
      }
    }
   
		office(id: $id, publicationState: LIVE) {
		    id
            URL
            Phone
            OfficeName
            About_Office
          About_Office_Title
          Show_CTA_Sticky_Mobile
          Address
             Modules {
      ... on GLSTRAPI_ComponentModuleTeamSlider {
              id
              Teams_Intro_Content
              Select_Peoples(sort: "Sort:asc",where: {Publish: true}) {
                Name
                Designation
                URL
                Tile_Image {
                  alternativeText
                  url
                }
                imagetransforms
                Video_URL
                Publish
              }
            }
              ... on GLSTRAPI_ComponentModuleFeaturedProperties {
              Place
              Search_Type
              id
            }
    
            ... on GLSTRAPI_ComponentModuleAddBlock {
              id
              Add_Service {
                Title
                CTA_Label
                Content
                Image {
                  alternativeText
                  url
                  formats
                }
                CTA_Link {
                  id
                }
                ImagePosition
              }
            }
            ... on GLSTRAPI_ComponentModuleMap {
              id
              Latitude
              Longtitude
            }
            ... on GLSTRAPI_ComponentModuleStaticContent {
              Add_Static_Content
            }
            ... on GLSTRAPI_ComponentModuleForms {
              id
              Name
              Title
            }
}
          BannerCTAs {
            CTA1_Form
            CTA1_Label
            CTA2_Form
            CTA2_Label
            _id
            id
            CTA1_Menu_Link {
              Label
              id
              URL
            }
            CTA2_Menu_Link {
              Label
              URL
              id
            }
          }
          Email
          Meta_Description
          Meta_Title
          Opening_Hours
          Phone
          Publish
          ShowGoogleRating
          URL
          Whatsapp
          _id
          id
          imagetransforms
          BannerImage {
        url
        id
      }
          TileImage {
            _id
            url
            id
          }
        }	
		
	  }   
  }
`